<template>
  <div class="ac-wemix-login-wrap">
    <div class="button-wrap">
      <md-button class="ac-wemix-login-btn"
                 @click="onQRAuth">
        <img :src="icon_wemix" alt="wemix"/>
        {{language.minting_connect_wallet_btn_1}}
      </md-button>
    </div>

    <div class="modal modal-bg"
         :class="{ show: isShowAgreeModal }" >
      <modal v-if="isShowAgreeModal"
             type="modal-agree"
             @close="hideAgreeModal">
        <template slot="header">
          <md-button class="md-simple md-close md-just-icon md-round modal-default-button"
                     @click="hideAgreeModal">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <ul class="modal-agree-list">
            <li v-for="(agree, index) in agreeList" :key="index">
              <template v-if="base.isDomestic">
                <el-checkbox class="modal-agree-item" v-model="agree.isAgree">{{language[agree.name]}}</el-checkbox>
                (<a :href="agree.target_url" target="_blank">{{language.minting_allowance_more_kr}}</a>)
              </template>
              <template v-else>
                <span class="modal-agree-item" >{{agree.name}}</span><a :href="agree.target_url">{{language.minting_allowance_more_kr}}</a>
              </template>
            </li>
          </ul>

          <div class="modal-agree-footer">
            <md-button class="md-round"
                       @click="hideAgreeModal">
              {{language.minting_allowance_cancel_btn_kr}}
            </md-button>
            <md-button class="md-round"
                       @click="onAgree">
              {{language.minting_allowance_ok_btn_kr}}
            </md-button>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { Modal } from "@/components";

export default {
  name: "auth",
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([
      "base",
      'language',
      "wemix",
      "isWemixAgree",
    ]),
  },
  watch: {
    isWemixAgree(value) {
      if (!this.isWemixLoggedIn && !value) {
        this.isShowAgreeModal = true;
      }
    },
    isShowAgreeModal() {
      if (typeof this.isWemixAgree === 'boolean' && !this.isWemixAgree) {
        this.SET_WEMIX_AGREE(null);
      }
    },
  },
  methods: {
    ...mapActions([
      'SUBMIT_WALLET_LOGIN_DATA',
      'SUBMIT_JOIN',
    ]),
    ...mapMutations([
      'SET_WEMIX_AGREE',
    ]),

    onQRAuth(event) {
      event.preventDefault();
      this.wemix.openQR("auth", null,
        success => {
          this.checkWalletLogin();
        },
        fail => {
          this.checkWalletLogin();

        }
      );
    },

    checkWalletLogin() {
      if (this.wemix.getStorage().wemix_ac_token) {
        this.wemix.login()
          .then(c => {
            this.SUBMIT_WALLET_LOGIN_DATA(c.data);
          })
      } else {
        console.log('localstorage wemix_ac_token 값 없음 = 로그인 화면 노출');
      }
    },

    onAgree(event) {
      let isAgree = true;

      if (this.base.isDomestic) {
        this.agreeList.forEach((item) => {
          if (!item.isAgree) isAgree = false;
          return false;
        });
      }
      if (isAgree) {
        this.agreeList.forEach((item) => {
          item.isAgree = false;
        });

        if (this.wemix.getStorage().wemix_ac_token) {
          this.wemix.login()
            .then(c => {
              this.SUBMIT_JOIN(c.data);
            })
        }
        this.hideAgreeModal(event);
      }
    },

    hideAgreeModal() {
      this.isShowAgreeModal = false;
    },
  },
  data() {
    return {
      icon_wemix: require("@/assets/img/logos/WEMIX_Symbol.png"),

      agreeList: [
        {isAgree: false, name: 'minting_allowance_kr', target_url: '/termsofuse'},
        {isAgree: false, name: 'minting_allowance_terms_kr', target_url: '/privacypolicy'},
      ],
      isShowAgreeModal: false,
    }
  }
};
</script>

<style lang="scss">

  $ac-section-bg: #ff6371 !default;

  .ac-wemix-login-wrap {
    .button-wrap {
      padding: 80px 0 130px;
      text-align: center;

      .ac-wemix-login-btn {
        background-color: #fff !important;
        display: inline-block;
        outline: none;
        text-align: center;
        text-decoration: none;
        color: black !important;
        font-size: 22px;
        font-weight: 700;
        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

        .md-ripple {
          padding: 15px 30px !important;
        }

        img {
          width: 34px;
          padding-right: 10px;
        }
      }
    }
  }

  .modal {
    .modal-header {

    }
    .modal-body {
      .modal-agree-list {
        list-style: none;
        text-align: left;
        margin: 0 auto;
        padding: 5%;
        font-size: 15px;

        .modal-agree-item {
          padding-bottom: 10px;
          font-size: 18px;
          font-weight: 800;
          color: #606266;

          .el-checkbox__label {
            font-size: 18px;
            font-weight: 800;
          }
        }
      }
    }
    .modal-agree-footer {
      display: block;
      width: 100%;
      overflow: hidden;
      padding-bottom: 20px;

      .md-button {
        width: 40%;
        margin: 0 5%;
        font-size: 16px;
        font-weight: 800;

        .md-ripple {
          padding: 10px 10px;
          background-color: #2f2b5a;
        }
      }
    }
  }
</style>