<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter"
              parallax-active="true"
              :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center">
            <h1 class="title">
              <img :src="image_head_title"/>
            </h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="auth-wrap" v-if="!isWemixLoggedIn">
      <Auth/>
    </div>

    <div v-else class="main main-raised">
      <div class="section">
        <div class="container">

          <div>
            <div class="md-layout-item md-size-100">
              <h4 style="text-align: center">
                <img :src="image_nft">
              </h4>
              <br>
            </div>

            <div v-if="isLoading" style="height: 200px">
              <div id="loading">
                <div class="spinner"></div>
              </div>
            </div>
            <div v-else>
              <div v-if="myNFT.length > 0" class="md-layout-item md-size-100">
                <md-table v-model="myNFT" class="">
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="NFT">
                      <router-link :to="`/NFT/detail/${item.token_id}`"
                                   class="img-container">
                        <img :src="item.image" alt="products">
                      </router-link>
                    </md-table-cell>
                    <md-table-cell md-label="ID" class="td-name">
                      {{ item.name }}
                    </md-table-cell>
                    <md-table-cell md-label="STATUS" class="td-number">
                      {{item.locked ? 'LOCK' : 'UNLOCK'}}
                    </md-table-cell>
                    <md-table-cell md-label="" class="td-button">
                      <md-button class="ac-nft-lock-btn md-round"
                                 type="button"
                                 title="NFT Lock"
                                 :value="item.locked"
                                 :disabled="item.cooltime > 0"
                                 @click="onLockAction(item, !item.locked)">
                        {{!item.locked ? 'LOCK' : 'UNLOCK'}}
                      </md-button>
                      <br>
                      <span v-if="item.cooltime > 0">{{item.remaining}}</span>
                    </md-table-cell>
                    <md-table-cell md-label="" class="td-button">
                      <md-button class="ac-nft-coupon-btn md-round"
                                 type="button"
                                 title="NFT Coupon"
                                 :value="item.locked"
                                 :disabled="item.cooltime > 0"
                                 @click="onGetCoupon(item)">
                        COUPON
                      </md-button>
                      <br>
                      <span v-if="item.cooltime > 0">{{item.remaining}}</span>
                    </md-table-cell>

                  </md-table-row>
                </md-table>
                <ul class="nft-info-text">
                  <li>
                    {{language.mypage_lock_infomation_1}}
                  </li>
                  <li>
                    {{language.mypage_lock_infomation_2}}
                  </li>
                </ul>
              </div>
              <div v-else class="none-nft">
                {{language.mypage_empty_infomation_1}}
              </div>
            </div>
          </div>

          <div class="wallet-login-out">
            <h4 style="text-align: center">
              <img :src="image_walletaddress">
            </h4>

            <span class="md-round">{{user.walletAddr}}</span>
            <br>
            <md-button class="ac-button md-round"
                       @click="onLogout">
              {{language.disconnect_wallet_btn}}
            </md-button>
          </div>

        </div>
      </div>
    </div>

    <div class="modal modal-bg"
         :class="{ show: isShowLockModal }" >
      <modal v-if="isShowLockModal"
             type="modal-small modal-lock"
             @close="isShowLockModal = false">
        <template slot="header">
          <md-button class="md-simple md-close md-just-icon md-round modal-default-button"
                     @click="isShowLockModal = false">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <p class="modal-lock-body" v-if="isSelectNFTLockStatus" style="color: black">{{language.mypage_conform_lock_01}}<br>{{language.mypage_conform_lock_02}}</p>
          <p class="modal-lock-body" v-else style="color: black">{{language.mypage_conform_unlock_01}}<br>{{language.mypage_conform_unlock_02}}</p>
        </template>

        <template slot="footer">
          <div class="modal-lock-footer justify-content-center d-flex">
            <md-button class=" md-round"
                       @click="isShowLockModal = false">
              {{language.mypage_conform_lock_cancle_btn}}
            </md-button>
            <md-button class="md-round"
                       @click="onLockActionAgree">
              {{language.mypage_conform_lock_ok_btn}}
            </md-button>
          </div>
        </template>
      </modal>
    </div>


    <div class="modal modal-bg"
         :class="{ show: isShowCouponModal }" >
      <modal v-if="isShowCouponModal"
             type="modal-coupon modal-small"
             @close="isShowCouponModal = false">
        <template slot="header">
          <h3>{{language.mypage_nft_coupon_btn_one_off_kr}}</h3>
          <md-button class="md-simple md-close md-just-icon md-round modal-default-button"
                     @click="isShowCouponModal = false">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <p class="modal-coupon-body" style="color: black">
            <span>{{language.mypage_nft_coupon_btn_number_kr}}</span><strong>{{coupon.number}}</strong><br>
            <span>{{language.mypage_nft_coupon_btn_time_limit_kr}}{{coupon.deadline}}</span><br>

          </p>
          <p class="modal-coupon-body" style="color: black">  </p>
        </template>

        <template slot="footer">
          <div class="modal-coupon-footer justify-content-center d-flex">
            <md-button class=" md-round"
                       @click="isShowCouponModal = false">
              {{language.mypage_nft_coupon_btn_ok_kr}}
            </md-button>
          </div>
        </template>
      </modal>
    </div>

  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import { Modal } from "@/components";
  import Mixins from "@/plugins/basicMixins";
  import timerCount from "@/plugins/timerCount";

  import Auth from "@/pages/auth/auth";

  export default {
    mixins: [Mixins.HeaderImage, timerCount],
    bodyClass: "ac-mypage",
    components: {
      Auth,
      Modal,
    },
    computed: {
      ...mapGetters([
        "base",
        "language",
        "isWemixLoggedIn",

        "isLoading",
        "wemix",
        "user",
        "myNFT",
      ]),
    },
    watch: {
      $route() {
        if (this.isWemixLoggedIn) {
          this.GET_MY_NFT_DATA();
        }
      },
      isWemixLoggedIn(value) {
        if (value) {
          this.GET_MY_NFT_DATA();
        }
      },
      myNFT() {
        this.stop();
        this.start(this.counts);
      },
    },
    mounted() {
      if (this.isWemixLoggedIn) {
        this.GET_MY_NFT_DATA();
      }

    },
    methods: {
      ...mapActions([
        "SUBMIT_WALLET_LOGIN_DATA",

        'GET_MY_NFT_DATA',
        'SUBMIT_TRANSFER_ABLE_DATA',
        'SUBMIT_LOG_OUT',
      ]),
      ...mapMutations([
        'SET_WEMIX_AGREE',
      ]),

      onLogout() {
        this.SUBMIT_LOG_OUT();
      },

      onQRAuth() {
        this.wemix.openQR("auth", null,
          success => {
            this.checkWalletLogin();
          },
          fail => {
            this.checkWalletLogin();
          }
        );
      },

      checkWalletLogin() {
        if (this.wemix.getStorage().wemix_ac_token) {
          this.wemix.login()
            .then(c => {
              this.SUBMIT_WALLET_LOGIN_DATA(c.data);
            })
        } else {
          console.log('localstorage wemix_ac_token 값 없음 = 로그인 화면 노출');
        }
      },

      counts() {
        this.myNFT.forEach((nft) => {
          if (nft.locked) {
            const cooltime = nft.cooltime - 1;

            if (cooltime > 0) {
              nft.cooltime = cooltime;

              const day = String(Math.floor(cooltime / (60 * 60 * 24)));
              const days = (day > 0) ? `${day}Day ` : '';
              const hour = String(Math.floor((cooltime % (60 * 60 * 24)) / (60 * 60))).padStart(2, '0');
              const min = String(Math.floor((cooltime % (60 * 60)) / 60)).padStart(2, '0');
              const sec = String(Math.floor(cooltime % 60)).padStart(2, '0');
              nft.remaining = `${days}${hour} : ${min} : ${sec}`;
            } else {
              nft.cooltime = 0;
            }
          }
        })
      },

      // NFT ///////////////////////////////////////////////////////////
      onLockAction(nft, lockStatus) {
        this.select_NFT = nft;
        this.isSelectNFTLockStatus = lockStatus;
        this.isShowLockModal = true;
      },

      onLockActionAgree() {
        // console.log('lock ------- = ', this.select_NFT);
        this.SUBMIT_TRANSFER_ABLE_DATA(this.select_NFT);
        this.select_NFT = null;
        this.isSelectNFTLockStatus = null;
        this.isShowLockModal = false;
      },

      onGetCoupon(nft) {
        this.isShowCouponModal = true;
        this.coupon = {
          number: nft.couponNumber,
          deadline: nft.couponDeadline,
        }
      }
    },
    data() {
      return {
        icon_wemix: require("@/assets/img/logos/WEMIX_Symbol.png"),

        image_head_title: require("@/assets/img/pages/mypage/my_page.png"),
        image_head_bg: require("@/assets/img/pages/mypage/04_news_bg2-2.png"),
        image_nft: require("@//assets/img/pages/mypage/nft.png"),
        image_walletaddress: require("@//assets/img/pages/mypage/walletaddress.png"),

        select_NFT: null,
        isShowLockModal: false,
        isShowCouponModal: false,
        isSelectNFTLockStatus: false,
        isLockActionAgree: false,
        coupon: {
          number: '',
          deadline: '',
        },



      }
    }
  }
</script>

<style lang="scss">
  .ac-mypage {

    .auth-wrap {
      background-color: #0c0a26;
    }
    .none-nft {
      height: 200px;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ac-mypage-contents {
      max-width: 700px;
      margin: 0 auto;
    }

    .md-table-head {
      text-align: center;

      .md-table-head-label {
        padding-right: 0;
        font-size: 15px;
        font-weight: 800;

        &:last-child {
          text-align: center;
        }
      }

    }

    .md-table-cell {
      text-align: center;

      .img-container {
        img {
          width: 120px;
        }
      }

      .md-table-cell-container {
        display: block;
        text-align: center;
        justify-content: normal;
        font-size: 18px;
        font-weight: 800;
        color: #2f2b5a;
        line-height: 15px;



        .md-ripple {
          font-size: 15px;
          font-weight: 800;
          line-height: 1.3;
          padding: 7px 18px 5px!important;
          background-color: #6825cc;

          &.md-disabled {
            background-color: #a7a7a7;
          }

          .md-button-content {
            font-weight: 800;
          }
        }

        .ac-nft-lock-btn {
          .md-ripple {
            background-color: #6825cc;
          }
        }

        .ac-nft-coupon-btn {
          .md-ripple {
            background-color: #f2418d;
          }
        }

      }
      &.td-button {
        .md-table-cell-container {
          line-height: 20px;
          span {
            font-weight: 500;
            font-size: 15px;
            color: #a7a7a7;
            letter-spacing: -1px;

          }
        }
      }
    }

    .nft-info-text {
      margin-top: 0;
      border-top: 1px solid #ccc;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.9em;
      padding: 3% 4% 3% 6%;
    }

    .wallet-login-out {
      margin-top: 60px;
      text-align: center;

      span {
        display: inline-block;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        color: #374768;
        margin: 20px 0;
        font-size: 12px;
        padding: 5px 2%;
        word-wrap: normal;
      }

      .ac-button {
        .md-ripple {
          background-color: #2f2b5a;
          font-weight: 600;
        }
      }
    }

    .modal-lock {
      .modal-lock-body {
        font-weight: 700;
        text-align: left;
      }

      .modal-lock-footer {
        display: block;
        width: 100%;
        overflow: hidden;
        padding-bottom: 10px;

        .md-button {
          width: 40%;
          margin: 0 5%;
          font-size: 16px;
          font-weight: 800;

          .md-ripple {
            padding: 10px 10px;
            background-color: #2f2b5a;
          }
        }
      }
    }
    .modal-coupon {
      h3 {
        font-weight: 800;
        color: #2f2b5a;
      }

      .modal-coupon-body {
        font-weight: 700;
        text-align: left;
      }
      .modal-coupon-footer {
        text-align: center;
        display: block;
        width: 100%;
        overflow: hidden;
        padding-bottom: 10px;

        .md-button {
          width: 40%;
          margin: 0 5%;
          font-size: 16px;
          font-weight: 800;

          .md-ripple {
            padding: 10px 10px;
            background-color: #2f2b5a;
          }
        }
      }
    }


  }
</style>